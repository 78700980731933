import wrapWithProvider from './gatsby/wrap-with-provider'
import wrapWithLayout from './gatsby/wrap-with-layout'

// import 'prismjs/themes/prism-tomorrow.css'/
// import "prismjs/plugins/command-line/prism-command-line.css"

//mobx
export const wrapRootElement = wrapWithProvider
export const wrapPageElement = wrapWithLayout

// ES5 way
// exports.onClientEntry = () => {
// ES6 way
export const onClientEntry = async () => {
    // if( typeof Proxy === 'undefined' ){
    //     Proxy = await require('proxy-polyfill/src/proxy')();
    //     console.log('# Proxy is polyfilled!');
    // }
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (typeof IntersectionObserver === 'undefined') {
        await require('intersection-observer');
        console.log('# IntersectionObserver is polyfilled!');
    }
}

const transitionDelay = 600

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
    //#用
    if (location.hash !== "") {
        return false
    }

    if (location.action === "PUSH") {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    } else {
        const savedPosition = getSavedScrollPosition(location)
        window.setTimeout(
            () => window.scrollTo(...(savedPosition || [0, 0])),
            transitionDelay
        )
    }
    return false
};