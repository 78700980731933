import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@c-atoms/Link"

// import { useObserver } from "mobx-react-lite"
// import { useStores } from '@hooks/useStores'
// import { Svg } from '@c-atoms/Svg'

import css from "@css/layout/l-header.module.styl"

export default function Header()
{
    const q = useStaticQuery(graphql`
        query {
            logo : file(relativePath: { eq: "Header__logo__marina.svg" }) { publicURL }
        }
    `)

    return(
        <header className={css.lHeader}>
            <div className={css.lHeader__inner}>
                <div className={css.lHeader__left}>
                    <h1>
                    <Link className={css.lHeader__logo} to="/">
                        <img src={q.logo.publicURL} alt="Marina official website. まりなの公式Webサイト"/>
                    </Link>
                    </h1>
                </div>
                <div className={css.lHeader__right}>
                </div>
            </div>
        </header>
    )
}

// function Menu(){
//     const menuLinks = useMenuLink()
//     return(
//         <nav className={css.menu}>
//             {
//                 menuLinks.map( ({label,to, isButton},i) => {
//                     return(
//                         <span key={i} className={`${ !isButton ? css.item : css.item_button }`}>
//                             <Link to={to}>{label}</Link>
//                         </span>
//                     )
//                 })
//             }
//         </nav>
//     )
// }
