import React from "react"

const css = {
    ...require("@css/atoms/a-copyright.module.styl"),
}

function Copyright() {

    return (
        <p className={css.aCopyright}>
            © {new Date().getFullYear()} Blue One.
        </p>
    )
}

export { Copyright }