// Hook
const menuData = [
    {
        label: 'HOME',
        to: '/',
    },{
        label: 'CONTACT',
        to: '/contact/',
    },{
        label: 'BLOG',
        to: 'https://marina-official.bitfan.id',
        blank: true,
    },{
        label: 'SHOP',
        to: 'https://store.bitfan.id/marina-official',
        blank: true,
    }
]
const snsData = [
    {
        label: 'BITFAN',
        to: 'https://marina-official.bitfan.id',
        blank: true,
    }, {
        label: 'Twitter',
        to: 'https://twitter.com/Mmmrn0118',
        blank: true,
    }, {
        label: 'Instagram',
        to: 'https://www.instagram.com/maririn_0118',
        blank: true,
    },{
        label: 'YouTube',
        to: 'https://www.youtube.com/channel/UCPlLDo8R00bbiqsTfmyWmbg/',
        blank: true,
    },{
        label: 'TikTok',
        to: 'https://www.tiktok.com/@maririn_0118',
        blank: true,
    }, {
        label: 'SHOWROOM',
        to: 'https://www.showroom-live.com/ambition_05marina',
        blank: true,
    }, {
        label: '755',
        to: 'https://7gogo.jp/BoCcXtdE33ja',
        blank: true,
    }
]

export { menuData, snsData }