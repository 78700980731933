/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
// import { Link } from "gatsby"
// import Image from 'gatsby-image'
import { gsap, Power4 } from 'gsap'
import { TransitionContext } from '@layouts/TransitionLayout'

import css from "@css/project/p-loading.module.styl"
import LoadingSVG from "@images/Header__logo__marina.svg"


export default function Loading(props) {
    const ref = React.useRef()
    const titleRef = React.useRef()
    const textRef = React.useRef()
    const transition = React.useRef(-1) 
    const loading = React.useRef(true)
    let { text } = props
    text = "Please wait..."


    React.useEffect(()=>{
        if (transition.current === 0){
            if (!loading.current) {
                return
            }
            loading.current = false
            gsap.set(ref.current, {
                x: `0%`,
            })
            gsap.to(ref.current, {
                ease: Power4.easeInOut,
                delay: 0.3,
                duration: 1.0,
                // opacity: 0,
                display: 'none',
                x: `-100%`,
                onComplete: ()=>{
                    gsap.set(ref.current, {
                        x: `100%`,
                    })
                }
            })
            //title
            gsap.to(titleRef.current, {
                delay: 0.3,
                duration: 0.3,
                ease: Power4.easeInOut,
                opacity: 0,
                x: `-50%`,
                onComplete: ()=>{
                    gsap.set(titleRef.current, {
                        x: `50%`,
                        opacity: 0,
                    })
                }
            })
        } else {
            if( loading.current ){
                return
            }
            loading.current = true
            gsap.to(ref.current, {
                ease: Power4.easeOut,
                duration: 0.6,
                // opacity: 1,
                display: 'flex',
                x: `0%`,
                
            })
            //title
            gsap.to(titleRef.current, {
                delay: 0.1,
                duration: 1.0,
                ease: Power4.easeOut,
                opacity: 1,
                x: `0%`,
            })
        }
    })


    return (
        <TransitionContext.Consumer>
            {({ transitionState, setTranstionState }) => {
                transition.current = transitionState
                return(
                    <div className={css.pLoading} ref={ref}>
                        <div className={css.pLoading__inner}>
                            <div className={css.pLoading__logo} ref={titleRef}>
                                <LoadingSVG />
                            </div>
                        </div>
                    </div>
                )
            }}
        </TransitionContext.Consumer>
    )
}
