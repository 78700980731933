import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { globalHistory } from '@reach/router'
import { gsap, Power4 } from 'gsap'

import LinkList from '@c-modules/LinkList'
import { menuData, snsData } from '@c-utility/UrlData'

import LogoSvg from "@images/Header__logo__marina.svg"

import css from "@css/project/p-modal-menu.module.styl"

export default function ModalMenu()
{
    let filColor = ["#EAEAEA","#EAEAEA"]

    const buttonRef = React.useRef()
    const topBorderRef = React.useRef()
    const bottomBorderRef = React.useRef() 
    const menuRef = React.useRef()
    const q = useStaticQuery(graphql`
        query {
            menu : file(relativePath: { eq: "Header__btn__menu.svg" }) { publicURL }
        }
    `)

    const [isOpen, setIsOpen] = React.useState(false)
    const menuHandler = () => {
        setIsOpen(!isOpen)

        if (isOpen ){
            gsap.to(topBorderRef.current,{
                duration: 0.3,
                ease: Power4.easeInOut,
                fill: filColor[0],
                rotate: '0deg',
                y: '0',
                transformOrigin: "50% 50%",
            })
            gsap.to(bottomBorderRef.current, {
                duration: 0.3,
                ease: Power4.easeInOut,
                fill: filColor[0],
                rotate: '0deg',
                y: '0',
                transformOrigin: "50% 50%",
            })
            gsap.to(menuRef.current, {
                duration: 0.3,
                ease: Power4.easeInOut,
                opacity: 0,
                display: 'none',
            })
        } else {
            gsap.to(topBorderRef.current, {
                duration: 0.3,
                ease: Power4.easeInOut,
                fill: filColor[1],
                rotate: '45deg',
                y: '6px',
                transformOrigin: "50% 50%",
            })
            gsap.to(bottomBorderRef.current, {
                duration: 0.3,
                ease: Power4.easeInOut,
                fill: filColor[1],
                rotate: '-45deg',
                y: '-6px',
                transformOrigin: "50% 50%",
            })
            gsap.to(menuRef.current, {
                duration: 0.3,
                ease: Power4.easeInOut,
                opacity: 1,
                display: 'flex',
            })
        }
    }
    let pathname = globalHistory.location.pathname

    return (
        <React.Fragment>
            <button className={css.pModalMenuButton} onClick={menuHandler} ref={buttonRef}>
                {/* <Svg src={q.menu.publicURL} /> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                    <g id="Header__btn__menu" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                        <rect ref={topBorderRef} width="40" height="4" x="0" y="13" fill={filColor[0]} />
                        <rect ref={bottomBorderRef} width="40" height="4" x="0" y="25" fill={filColor[0]} />
                    </g>
                </svg>
            </button>
            <nav className={css.pModalMenu} style={{display: 'none' , opacity: 0}} ref={menuRef}>
                <div className={css.pModalMenu__inner}>
                    <div className={css.pModalMenu__logo}>
                        <LogoSvg/>
                    </div>
                    <LinkList className={css.pModalMenu__menu} data={menuData} onClick={menuHandler}/>
                    <LinkList className={css.pModalMenu__sns} data={snsData} onClick={menuHandler}/>
                </div>
            </nav>
        </React.Fragment>
    )
}
