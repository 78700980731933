import React from "react"
import { Link } from "@c-atoms/Link"

export default function LinkList({ className,data, ...props }) {
    return (
        <div className={className}>
            <ul>
                { data.map(({ label, to, scroll, blank}, i) => {
                    return (
                        <li key={i}>
                            <Link to={to} scroll={scroll} blank={blank} {...props}>{label}</Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
