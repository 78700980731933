// import React,{ useRef } from 'react'
// import * as THREE from 'three'
// import { useThree } from "react-three-fiber"
import {observable} from 'mobx'
// import 'mobx-react-lite/batchingForReactDom'
// import { WEBGL } from 'three/examples/jsm/WebGL.js'
// import browser from 'browser-detect'

class AppStore {
    @observable marqueeData = {}
}

export default AppStore