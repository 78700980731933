import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@c-atoms/Link"
// import { FluidImage } from "@c-atoms/Image"
// import { Svg } from "@c-atoms/Svg"
import LinkList from "@c-modules/LinkList"
import { menuData, snsData } from "@c-utility/UrlData"
import { Copyright } from "@c-atoms/Copyright"

const css = {
    ...require("@css/layout/l-footer.module.styl"),
    ...require("@css/project/p-footer-menu.module.styl"),
}

export default function Footer()
{
   
    return(
        <footer className={css.lFooter}>
            <div className={css.lFooter__inner}>
                <div className={css.lFooter__menu}>
                    <LinkList className={css.pFooterMenu} data={menuData} />
                </div>
                <div className={css.lFooter__menuSns}>
                    <LinkList className={css.pFooterMenu} data={snsData} />
                </div>
                <div className={css.lFooter__copyright}>
                    <Copyright/>
                </div>
            </div>
        </footer>
    )
}